import React, {useState, useEffect, useMemo} from 'react';

import api from "../../../helpers/gestora/api"

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
import {dateBrToISO, MoneyFormat} from "../../../renders/utils";

import CustomForm from "../../../components/Common/CustomForm/Form";
import {
	datePeriodOptions, fundOptions,
	redemptionStatusOptions,
	requestStatusOptions,
	socialSecurityStatusOptions
} from "../../../constants/formLists"
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";
moment.locale('pt-BR')
import Button from "../../../components/Common/Button/Button"

import {parseMoney} from "../../../helpers/parser_helper";
import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import {DataDate} from "../../../components/Common/Tables/Datatable/DataTypes";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../common/url";
import {AuthInfo, UserCan} from "../../../helpers/gestora/auth";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {useLocalStorage} from "../../../helpers/gestora/UseLocalStorage";
import ModalForm from "../../../components/Common/Modals/ModalForm";

const defaultDate = [
	moment().startOf('month').format('YYYY-MM-DD'),
	// moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

const statusTabs = [
	{id: null, caption:"Todas"},
	...socialSecurityStatusOptions,
]

export const SocialSecuritySearch = () => {
	const formId = 'SocialSecuritySearch'
	const location = useLocation()
	const history = useHistory()

	const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || '');
	const [selectedIds, setSelectedIds] = useState([])
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [changePaymentDate, setChangePaymentDate] = useState(null)

	const [approvingMany, setApprovingMany] = useState(false)
	const [changingPaymentDateMany, setChangingPaymentDateMany] = useState(false)
	const [sendingEmailMany, setSendingEmailMany] = useState(false)
	const [exportingResult, setExportingResult] = useState(false)

	const [viewId, setViewId] = useState(0)


	const app = useOvermind('app')

	const apiEndpoint = {
		path : `social-security`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			submitForm, setModelValueByPath,
			setFormState, setFieldState, setFormModel, resetForm,
			updateModelValueValues, incFormValidatedCount, incFormResetCount
		}
	} = useOvermind('forms')

	const investments = useOvermind('investments')

	const handleApproveSelected = () => {
		if (!confirm('Deseja realmente aprovar os itens selecionados?'))
			return

		setApprovingMany(true)

		investments.actions.approveMany(selectedIds)
			.then(res => {
				setSelectedIds([])
				refreshList()
			})
			.catch(e => {
				alert(`Ocorreu um erro ao salvar: ${e.toString()}`)
			})
			.finally(() => {
				setApprovingMany(false)
			})
	}

	const changePaymentDateFields = useMemo(() => [
		{
			id:'title',
			caption:'Alterar data de pagamento',
			type:'title',
			required:true,
			col:12
		},
		{
			id:'date',
			caption:'Data de pagamento',
			type:'date',
			required:true,
			col:12
		},
	], [])


	const handleChangePaymentDate = (values) => {

		setChangingPaymentDateMany(true)
		investments.actions
			.changePaymentDateMany({ids:selectedIds, date:dateBrToISO(values.date)})
			.then(res => {
				setSelectedIds([])
				setChangePaymentDate(null)
				refreshList()
			})
			.catch(e => {
				const error = e?.response?.data?.error
				if (error==='not_business_day') {
					alert(`Não foi possível processar, a data definida não é um dia útil.`)
				} else {
					alert(`Ocorreu um erro ao processar: ${e.toString()}`)
				}
			})
			.finally(() => {
				setChangingPaymentDateMany(false)
			})
	}

	const handleResendEmailSelected = () => {
		if (!confirm('Deseja re-enviar os e-mails selecionados aos planejadores ?'))
			return

		setSendingEmailMany(true)

		investments.actions.reSendEmailMany(selectedIds)
			.then(res => {
				setSelectedIds([])
				refreshList()
			})
			.catch(e => {
				alert(`Ocorreu um erro ao enviar: ${e.toString()}`)
			})
			.finally(() => {
				setSendingEmailMany(false)
			})


	}

	const handleResendEmailToClientSelected = () => {
		if (!confirm('Deseja re-enviar os e-mails selecionados aos clientes ?'))
			return

		setSendingEmailMany(true)

		investments.actions.reSendEmailToClientMany(selectedIds)
			.then(res => {
				setSelectedIds([])
				refreshList()
			})
			.catch(e => {
				alert(`Ocorreu um erro ao enviar: ${e.toString()}`)
			})
			.finally(() => {
				setSendingEmailMany(false)
			})
	}

	const handleExportResult = (evt, fileTitle) => {

		setExportingResult(true)

		api
			.post(`admin/investments/downloadxls`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			periodStart : model?.period?.length && model?.period[0],
			periodEnd : model?.period?.length && model?.period[1],
			operation : model?.operation,
			fund : model?.fundId,
			requestStatus: model?.request_status,
			redemptionStatus: filterStatus || model?.redemption_status || undefined
		})
	}

	useEffect(() => {
		if (history.location?.state?.period) {
			if (model?.period) {
				refreshList()
			}
		} else {
			refreshList()
		}
	}, [filterStatus, model?.operation, model?.fundId, filterParams, model?.period, model?.request_status, model?.redemption_status])

	useEffect(() => {
			setModelValueByPath({formId, path:'period', value:history.location?.state?.period || null})

		if (history.location?.state?.status)
			setModelValueByPath({formId, path:'status', value:history.location?.state?.status})
	}, [history.location])

	useEffect(() => {
		updateModelValueValues({formId, values:{
			fund:null,
			operation:null,
			requestStatus: null,
			redemptionStatus: null
		}})
	}, [location])


	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
			updatedAt : model.updatedAt ? dateBrToISO(model.updatedAt) : null,
			paymentAt : model.paymentAt ? dateBrToISO(model.paymentAt) : null,
			value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		setViewId(row.id)
	};

	const columns = useMemo(() => [
		{
			id : 'client_name',
			name: <CustomHeader onComplete={handleFilterChange} id={'client_name'} formId={formId} fieldProps={{type:'text'}}>Cliente</CustomHeader>,
			grow:1,
			selector: row => row?.client_name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'id_certificate',
			name: <CustomHeader onComplete={handleFilterChange} id={'client_name'} formId={formId} fieldProps={{type:'text'}}>Certificado</CustomHeader>,
			grow:1,
			selector: row => row?.id_certificate,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'tax_regime',
			name: <CustomHeader onComplete={handleFilterChange} id={'tax_regime'} formId={formId} fieldProps={{type:'text'}}>Regime Tributário</CustomHeader>,
			grow:1,
			selector: row => row?.tax_regime,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'request_date',
			name: "Data Solicitação",
			grow:0,
			width:'128px',
			selector: function A(row) {
				return <DataDate value={row?.request_date}/>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
		{
			id : 'estimated_payment_date',
			name: "Data Estimada Pagamento",
			grow:0,
			width:'160px',
			selector: function A(row) {
				return <DataDate value={row?.estimated_payment_date}/>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
		{
			id : 'operation_type',
			name: <CustomHeader onComplete={handleFilterChange} id={'client_name'} formId={formId} fieldProps={{type:'text'}}>Tipo</CustomHeader>,
			grow:1,
			width: '96px',
			selector: row => row?.operationType,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'gross_amount_requested',
			name: <CustomHeader onComplete={handleFilterChange} id={'gross_amount_requested'} formId={formId} fieldProps={{type:'money'}}>Valor Bruto Solicitado</CustomHeader>,
			width:'160px',
			grow:0.3,
			selector: row => MoneyFormat(row?.gross_amount_requested),
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'request_status',
			name: <CustomHeader onComplete={handleFilterChange} id={'request_status'} formId={formId} fieldProps={{type:'text'}}>Status Solicitação</CustomHeader>,
			grow:1,
			selector: row => row?.request_status,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'redemption_status',
			name: <CustomHeader onComplete={handleFilterChange} id={'redemption_status'} formId={formId} fieldProps={{type:'text'}}>Status Resgate</CustomHeader>,
			grow:1,
			selector: row => row?.redemption_status,
			sortable: true,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'period',
					caption:'Período',
					type:'daterange',
					options:datePeriodOptions,
					emptyOptionText:<b>Tudo</b>,
					required:true,
					col:3
				},
				{
					id:'fundId',
					caption:'Fundo',
					type:'select2',
					emptyOptionText : <b>Todos</b>,
					// listId:'InvestmentFunds',
					options: fundOptions,
					required:true,
					col:3
				},
				{
					id:'request_status',
					caption:'Status Solicitação',
					type:'select2',
					emptyOptionText : <b>Todos</b>,
					options:requestStatusOptions,
					required:true,
					col:3
				},
				{
					id:'redemption_status',
					caption:'Status Resgate',
					type:'select2',
					emptyOptionText : <b>Todos</b>,
					options:redemptionStatusOptions,
					required:true,
					col:3
				},
			],
		},
	], []);

	return (<>
		<ModalForm
			data={selectedIds}
			isVisible={!!changePaymentDate}
			fields={changePaymentDateFields}
			confirmMessage={`Deseja mesmo alterar a data de resgate das movimentações selecionadas?`}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={() => {
				setChangePaymentDate(null)
			}}
			onConfirm={handleChangePaymentDate}
		/>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Previdências"  >
				</DefaultPageHeader>
			}
		>
			<CustomForm
				className="needs-validation "
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				<PageDatatable
					formId={formId}
					tabs={statusTabs}
					activeTab={filterStatus}
					onTabChange={tabId => setFilterStatus(tabId)}
					TabActions={<>
						<UserCanAll permission="investments.edit">
						<Button
							loading={changingPaymentDateMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-danger  m-1 btn-xs"
							// disabled={currentScreen==0}
							onClick={() => setChangePaymentDate(true)}>
							<i className="m-1 bx bx-calendar align-middle"></i>
							Alterar Dt Pagamento
						</Button>
						<Button
							loading={approvingMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-danger  m-1 btn-xs"
							onClick={handleApproveSelected}>
							<i className="m-1 bx bx-check-square align-middle"></i>
							Aprovar em lote
						</Button>
						<Button
							loading={sendingEmailMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-primary  m-1 btn-sm"
							onClick={handleResendEmailSelected}>
							<i className="m-1 fa fa-mail-bulk align-middle"></i>
							Enviar e-mail
						</Button>
						</UserCanAll>

						{AuthInfo().id=='30' ?
						<Button
							loading={sendingEmailMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-primary  m-1 btn-sm"
							onClick={handleResendEmailToClientSelected}>
							<i className="m-1 fa fa-mail-bulk align-middle"></i>
							Enviar e-mail para clientes
						</Button> : null}
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						<Button
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							onClick={() => setCompactMode(!compactMode)}>
							<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>
						</Button>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							tabId={formId}
						/>
					}
				>
					<Datatable
						selectableRows={UserCan('investments.edit')}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</>
	);
};

export default {
	title: 'Pagination/Remote',
	component: SocialSecuritySearch,
};
