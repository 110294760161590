import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import RegistrationForm from "../pages/Clients/RegistrationForm/RegistrationForm"
import { ListRegistrationForm } from "../pages/Clients/List";
import {InvestmentsSearch} from "../pages/Investments/Search";
import {ClientSearch} from "../pages/Clients/Search";
import {FundTypesSearch} from "../pages/SupportTables/FundTypes/Search";
import {InvestmentsFirstRelationSearch} from "../pages/InvestmentsFirstRelation/Search";
import {BanksSearch} from "../pages/SupportTables/Banks/Search";
import {RiskProfileSearch} from "../pages/SupportTables/RiskProfile/Search";
import RiskProfileView from "../pages/SupportTables/RiskProfile/View";
import {SuitabilitySearch} from "../pages/SupportTables/Suitability/Search";
import SuitabilityView from "../pages/SupportTables/Suitability/View";
import {UsersSearch} from "../pages/SupportTables/Users/Search";
import {ProfilesSearch} from "../pages/SupportTables/Profiles/Search";
import MyAccountView from "../pages/MyAccount/View";
import {MailSigSearch} from "../pages/SupportTables/MailSig/Search";
import MailSigGuestUpdatePage from "../pages/SupportTables/MailSig/GuestUpdate";
import {ClientLogDashboard} from "../pages/ClientLog/Dashboard";
import {IcatuCertificatesReportSearchPage} from "../pages/IcatuCertificatesReport/Search";
import { SocialSecuritySearch } from "../pages/SocialSecurity/Search"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/clientLog/dashboard", component: ClientLogDashboard },

  { path: "/clients/registrationform", component: function A(props) { return  <RegistrationForm key={Date.now()} {...props}  />  }},
  { path: "/clients/registrationform/:registrationFormId", component: RegistrationForm},
  { path: "/clients/list", component: ListRegistrationForm },
  { path: "/clients/search", component: function A(props) { return <ClientSearch key={Date.now()} {...props}  /> }},
  { path: "/investments/search", component: function A(props) { return <InvestmentsSearch key={Date.now()} {...props}  /> }},
  { path: "/social-security/search", component: function A(props) { return <SocialSecuritySearch key={Date.now()} {...props}  /> }},
  { path: "/investmentsfirstrelation/search", component: function A(props) { return <InvestmentsFirstRelationSearch key={Date.now()} {...props}  /> }},
  { path: "/icatuCertificates/search", component: function A(props) { return <IcatuCertificatesReportSearchPage key={Date.now()} {...props}  /> }},
  { path: "/supportTables/fundTypes/search", component: function A(props) { return <FundTypesSearch key={Date.now()} {...props}  /> }},
  { path: "/supportTables/users/search", component: function A(props) { return <UsersSearch key={Date.now()} {...props}  /> }},
  { path: "/supportTables/profiles/search", component: function A(props) { return <ProfilesSearch key={Date.now()} {...props}  /> }},
  { path: "/supportTables/banks/search", component: function A(props) { return <BanksSearch key={Date.now()} {...props}  /> }},

  { path: "/supportTables/mailsig/search", component: function A(props) { return <MailSigSearch key={Date.now()} {...props}  /> }},

  { path: "/supportTables/riskProfile/search", component: function A(props) { return <RiskProfileSearch key={Date.now()} {...props}  /> }},
  { path: "/supportTables/riskProfile/:id", component: function A(props) { return <RiskProfileView key={Date.now()} {...props}  /> }},

  { path: "/supportTables/suitability/search", component: function A(props) { return <SuitabilitySearch key={Date.now()} {...props}  /> }},

  { path: "/supportTables/suitability/:id", component: function A(props) { return <SuitabilityView key={Date.now()} {...props}  /> }},
  { path: "/myAccount", component: function A(props) { return <MyAccountView key={Date.now()} {...props}  /> }},

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/emailSignature", component: function A(props) { return <MailSigGuestUpdatePage key={Date.now()} {...props}  /> }},
]

export { publicRoutes, authProtectedRoutes }
